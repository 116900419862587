.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;

  > div:nth-child(1) {
    flex-grow: 1;
    margin-right: 54px;
    margin-top: 12px;
  }

  > div:nth-child(2) {
    flex-grow: 1;
  }

  > div:nth-child(3) {
    margin-right: 15px;
  }
}

.wrapper {
  display: flex;
  align-items: flex-end;
}
