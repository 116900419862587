.modal {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  &_body {
    position: absolute !important;
    background: rgb(255, 255, 255);
    width: 50%;
    max-height: 95%;

    &_content {
      padding: 15px;

      .cross {
        position: absolute;
        top: 25px;
        right: 25px;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }

      .modal_text,
      .modal_title {
        text-align: center;
      }

      .modal_text {
        margin: 35px 0;
      }

      .btn_gap {
        margin: 0 15px;
        min-width: 130px;
      }
    }
  }
}
