@import '../../styles/shared.scss';

.input_label {
  color: black !important;
  font-weight: bold !important;
}

.invalid_text {
  color: $primary-color;
  font-size: 12px;
}

.invalid {
  border-bottom: 2px solid $primary-color;
}

.underline {
  border-bottom: 2px solid $light-grey;
}
