.wrapper_text_edit {
  width: 100%;
  border: 1px solid #c4c4c4;
  padding: 5px;
  border-radius: 3px;
  background-color: #c4c4c426;

  &:hover {
    border-color: black;
  }
}

.editor_text_edit {
  border: 1px solid #c4c4c4;
  height: 185px !important;
  background-color: white;

  &:hover {
    border: 1px solid black;
  }
}

.label_text {
  color: black;
  font-weight: bold;
}

.required {
  color: red;
}

.error_message {
  color: red;
  width: 100%;
  padding-left: 14px;
  margin-top: 3px;
  font-size: 0.75rem;
  font-family: Roboto, serif;
  min-height: 14px;
}

.has_error {
  border-color: red !important;
}
