.form {
  .input_label {
    font-size: 14px;
    position: static;
    transform: unset;
    color: #000;
    font-weight: bold;
    margin: 10px 0 10px 10px;
  }

  .datepicker {
    width: 100%;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
    height: 42px;
    color: #828282;
    font-size: 14px;
    padding-left: 10px;

    &:focus {
      border: 2px solid #a9a9a9;
      outline: none;
    }
  }

  .btn {
    width: 100%;
  }

  .btn_selected {
    width: 100%;
    background: #f0f0f0;
  }
}
