.container {
  border: none;

  :global(.MuiDataGrid-columnsContainer) {
    background-color: #eee;
  }

  :global(.MuiDataGrid-colCellTitle) {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
  }

  :global(.MuiDataGrid-iconSeparator) {
    display: none;
  }

  :global(.MuiDataGrid-columnsContainer),
  :global(.MuiDataGrid-row) {
    border-bottom-color: #bcbcbc !important;
  }

  :global(.disabled-row) {
    background-color: #d5d2d2 !important;
  }

  :global(.MuiDataGrid-cell--textLeft) {
    padding-left: 16px;
  }

  :global(.MuiDataGrid-colCell:focus),
  :global(.MuiDataGrid-cell:focus) {
    outline: unset !important;
  }

  :global(.MuiDataGrid-cell[data-field='name']) {
    font-weight: bold;
    text-decoration: underline;
  }

  :global(.MuiDataGrid-cell[data-field='name']):hover {
    cursor: pointer;
  }
}
