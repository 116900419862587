.linkTab {
  padding: 18px 36px;
  text-decoration: none;
  font-size: 16px;
  line-height: 19px;
  color: #0a0a0a;
  background-color: #eee;
}

.selected {
  font-weight: 700;
  background-color: #f8f8f8;
  border-bottom: 2px solid #f00;
}
