.container {
  display: flex;
  height: 40vw;
  white-space: pre-wrap;
  margin-top: 20px;
}

.policy {
  overflow-y: scroll;
}
