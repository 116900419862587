.container {
  min-width: 600px;
  padding: 20px;
  max-width: 900px;
  position: relative;
  background-color: white;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invalid_text {
  color: #f00;
}

.question {
  margin: 5px;
}

.requirements_text {
  color: rgba(0, 0, 0, 0.54);
  margin-left: 14px;
  font-size: 0.75rem;
  padding-bottom: 15px;
}

.error_text {
  color: #f00;
  margin-left: 14em;
}

.cross {
  position: absolute;
  top: 38px;
  right: 38px;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.input_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 30px;
}

.delete_container {
  display: flex;
  align-self: center;
  margin-right: auto;
  margin-left: 10px;

  .delete_link {
    display: flex;
    align-items: center;
  }

  .disable_link {
    display: flex;
    align-items: center;
    color: grey;
    cursor: default;
    text-decoration: none;
  }
}
