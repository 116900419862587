.form {
  position: static;
  width: 15%;
  margin-top: 3px;

  .title {
    font-size: 14px;
    position: static;
    transform: unset;
    color: #000;
  }

  >div:nth-child(2),
  div:nth-child(3) {
    >div {
      >div:nth-child(1) {
        height: 48px;
        margin-top: 5px;
        border: 2px solid #e0e0e0;
        border-radius: 4px;
        padding: 0 0 0 16px;

        fieldset {
          border: none;
        }

        input {
          color: #828282;
          font-size: 14px;
          padding-left: 0;
        }

        &:focus-within {
          border: 2px solid #a9a9a9;
        }
      }
    }

    &::before {
      font-size: 14px;
    }
  }
}
