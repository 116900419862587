@import '../../../../styles/colors';

.container {
  background-color: white;
  height: 92%;
  overflow-y: auto;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &_body {
    max-width: 900px;

    &_content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 15px;

      .cross {
        position: absolute;
        top: 38px;
        right: 38px;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }

      .modal_text {
        text-align: center;
      }

      .input_container {
        display: flex;
        align-items: center;
        padding-left: 35px;

        .aligned_block {
          margin-top: -23px;
        }

        .input_label {
          font-weight: bold;
          color: black !important;

          .required {
            color: $primary-color;
          }
        }
      }

      .invalid_text {
        color: #f00;
        font-size: 12px;
      }

      .hint {
        font-weight: bold;
        color: gray;
        margin-bottom: 20px;
      }

      .btn_gap {
        margin: 0 15px;
      }

      .delete_container {
        display: flex;
        align-self: center;
        margin-right: auto;
        margin-left: 15px;

        .delete_link {
          display: flex;
          align-items: center;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &_footer {
    flex-direction: column;
  }
}
