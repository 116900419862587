.container {
  display: flex;
  margin: 24px 0;

  > div:nth-child(1) {
    flex-grow: 2;
    margin-right: 24px;
  }

  > div:nth-child(2) {
    margin-right: 24px;
  }
}
