.container {
  background-color: white;
  height: 92%;
  overflow-y: auto;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &_body {
    width: 900px;

    &_content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 15px;

      .sendingTime {
        display: flex;
        align-items: center;

        .timeItem {
          margin: 0 24px 0 12px;
        }
      }

      .activated {
        margin-left: 12px;
        display: flex;
        align-items: center;
      }

      .cross {
        position: absolute;
        top: 38px;
        right: 38px;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }

      .modal_text {
        text-align: center;
      }
    }
  }
}
