.tabs {
  display: flex;
}

.btn {
  position: absolute;
  right: 0;
  top: 112px;
}

.table {
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
