@import '../../styles/shared.scss';

.button {
  background-color: $primary-color !important;
  color: $white !important;
  text-transform: none !important;
  font-weight: bold !important;

  &:disabled {
    background-color: $secondary-color !important;
  }
}
