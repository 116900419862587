@import '../../styles/shared.scss';

.btn {
  width: 100%;
}

.disabled {
  background-color: $secondary-color !important;
  color: $white !important;
}

.rounded_btn {
  border-radius: 4px;
}
