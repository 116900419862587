.form {
  position: static;

  .input_label {
    font-size: 14px;
    position: static;
    transform: unset;
    color: #000;
    margin-left: 10px;
  }

  .input_plaseholder {
    font-size: 14px;
    color: #828282;
    font-style: normal;
  }

  .select {
    width: 210px;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
    height: 48px;
    margin-top: 8px;
    color: #828282;
    font-size: 14px;

    &:focus-within {
      border: 2px solid #a9a9a9;
    }

    div {
      color: #828282;
      font-size: 14px;
      padding: 16px;
    }

    svg {
      margin-right: 12px;
    }
  }
}
