.error_indicator_wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  .title {
    color: red;
  }

  .text_content {
    font-size: 1.3em;
    flex-basis: 0;
    text-align: center;
  }

  .btn_wrap {
    flex-basis: 0;
    margin-top: 20px;
  }
}
