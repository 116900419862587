.tabs {
  display: flex;
}

.table {
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
