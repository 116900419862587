.add_btn {
  display: flex;
  border-radius: 4px;
  padding: 14px;
  margin: 5px 24px 0 auto;

  .plus {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 12px;
  }
}
