@import '../../styles/shared.scss';

.btn {
  width: 100%;
}

.disabled {
  background-color: $white;
  color: $primary-color;
}

.rounded_btn {
  border-radius: 4px;
}
