@import '../../../../../styles/colors';

.modal {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  &_body {
    position: absolute !important;
    background: rgb(255, 255, 255);
    padding: 25px 25px;

    &_content {
      width: 372px;

      .cross {
        position: absolute;
        top: 38px;
        right: 38px;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }

      .modal_input {
        width: 100%;
        margin: 16px 0;
        min-height: 73px;
      }

      .modal_text {
        text-align: center;
      }

      .modal_label {
        font-weight: bold !important;
        font-size: 12px !important;
        color: rgb(0, 0, 0) !important;
        margin: 32px 0 4px 0;
      }

      .modal_role {
        width: 100%;
        font-size: 16px;
        line-height: 19px;
        color: black;
      }

      .btn_gap {
        margin-top: 48px;
      }
    }
  }
}
