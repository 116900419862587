.form {
  position: static;

  .input_label {
    font-size: 14px;
    position: static;
    transform: unset;
    color: #000;
  }

  .input {
    border: 2px solid #e0e0e0;
    border-radius: 4px;
    margin-top: 8px;
    height: 48px;
    padding: 16px;

    &:focus-within {
      border: 2px solid #a9a9a9;
    }

    input {
      &::placeholder {
        color: #828282;
        font-size: 12px;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }
  }
}
