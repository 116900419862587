:global(.MuiDataGrid-footerContainer) {
  position: relative;
}

.title {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
