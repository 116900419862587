.btn_gap {
  margin-left: 25px;
}

.delete_container {
  display: flex;
  align-self: center;
  margin-right: auto;

  .delete_link {
    display: flex;
    align-items: center;
  }
}
