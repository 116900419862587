.btn {
  position: absolute;
  right: 0;
  top: 112px;
}

.table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
