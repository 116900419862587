/* global styles */
@import 'reactDatepicker';

body {
  margin: 0;
}

* {
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.ttf');
  src: local('Roboto-Regular'), url('../assets/fonts/Roboto-Regular.ttf') format(truetype);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../assets/fonts/Roboto-Italic.ttf');
  src: local('Roboto-Italic'), url('../assets/fonts/Roboto-Italic.ttf') format(truetype);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../assets/fonts/Roboto-Bold.ttf');
  src: local('Roboto-Bold'), url('../assets/fonts/Roboto-Bold.ttf') format(truetype);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../assets/fonts/Roboto-BoldItalic.ttf');
  src: local('Roboto-BoldItalic'), url('../assets/fonts/Roboto-BoldItalic.ttf') format(truetype);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../assets/fonts/Roboto-Thin.ttf');
  src: local('Roboto-Thin'), url('../assets/fonts/Roboto-Thin.ttf') format(truetype);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('../assets/fonts/Roboto-ThinItalic.ttf');
  src: local('Roboto-ThinItalic'), url('../assets/fonts/Roboto-ThinItalic.ttf') format(truetype);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../assets/fonts/Roboto-Light.ttf');
  src: local('Roboto-Light'), url('../assets/fonts/Roboto-Light.ttf') format(truetype);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../assets/fonts/Roboto-LightItalic.ttf');
  src: local('Roboto-LightItalic'), url('../assets/fonts/Roboto-LightItalic.ttf') format(truetype);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../assets/fonts/Roboto-Medium.ttf');
  src: local('Roboto-Medium'), url('../assets/fonts/Roboto-Medium.ttf') format(truetype);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../assets/fonts/Roboto-MediumItalic.ttf');
  src: local('Roboto-MediumItalic'), url('../assets/fonts/Roboto-MediumItalic.ttf') format(truetype);
  font-weight: 300;
  font-style: italic;
}
