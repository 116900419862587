.react-datepicker__day--keyboard-selected {
  background-color: transparent;
  color: black;
}

.react-datepicker__day--keyboard-selected.day--bg-transparent {
  background-color: transparent;
  color: black;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--range-start {
  background-color: #216ba5;
  color: white;
}

.react-datepicker__day--outside-month {
  color: #828282 !important;
  pointer-events: none;
}
